export const api = {

    /** 暂无图片 */
    IMG_URL: 'https://cdn.aozhouxiaopu.cn',
    IMG_ERR: `https://cdn.aozhouxiaopu.cn/static/images/noGoodsPic.png`,


    LOGIN: '/api/nbx/bws/login',

    /** cus 相关 */
    /** uuid换取jwt */
    CUS_UUID_GET_JWT: '/api/nbx/bws/cus/uuidGetJwt',
    /** 刷新token */
    CUS_REFRESH_TOKEN: '/api/nbx/bws/cus/refreshToken',
    /** 登录 */
    CUS_LOGIN: '/api/nbx/bws/cus/login',
    /** 注册 */
    CUS_REGISTER: '/api/nbx/bws/cus/register',
    /** 登出 */
    CUS_LOGOUT: '/api/nbx/bws/cus/logout',
    /** 顾客信息 */
    CUS_INFO: '/api/nbx/bws/cus/cusInfo',


    /** goods 相关 */
    /** 商品列表 */
    GOODS_LIST: '/api/nbx/bws/goods/search',
    /** 商品详情 */
    GOODS_DETAIL: '/api/nbx/bws/goods/detail',
    /** 首页栏位 */
    GOODS_HOME_FIELD: '/api/nbx/bws/goods/homeField',

    /** 购物车相关 */
    /** 购物车 - 商品列表 */
    SHOPPING_CART_LIST: '/api/nbx/bws/basket/list',
    /** 购物车 - 添加商品 */
    SHOPPING_CART_PUSH_GOODS: '/api/nbx/bws/basket/pushGoods',
    /** 购物车 - 修改商品数量 */
    SHOPPING_CART_UPDATE_QUANTITY: '/api/nbx/bws/basket/updateQuantity',
    /** 购物车 - 修改商品选中 */
    SHOPPING_CART_CHECK: '/api/nbx/bws/basket/check',
    /** 购物车 - 商品全部选中/反选 */
    SHOPPING_CART_CHECK_ALL: '/api/nbx/bws/basket/checkAll',
    /** 购物车 - 删除商品 */
    SHOPPING_CART_DEL_GOODS: '/api/nbx/bws/basket/delGoods',
    /** 购物车 - 统计勾选的商品数量 */
    SHOPPING_CART_CONT_CHECKED: '/api/nbx/bws/basket/contChecked',

    /** 发件人/收件人相关 */
    /** 地址：收件人-列表 */
    ADDRESS_LIST: '/api/nbx/bws/address/list',
    /** 地址：收件人-根据ID查询 */
    ADDRESS_GET_BY_ID: '/api/nbx/bws/address/getById',
    /** 地址：收件人-新增 */
    ADDRESS_ADD: '/api/nbx/bws/address/add',
    /** 地址：收件人-修改 */
    ADDRESS_UPDATE: '/api/nbx/bws/address/update',
    /** 地址：收件人-删除 */
    ADDRESS_DEL: '/api/nbx/bws/address/del',
    /** 地址：发件人/订购人-列表 */
    ADDRESS_SENDER_LIST: '/api/nbx/bws/address/listSender',
    /** 地址：发件人/订购人-根据ID查询 */
    ADDRESS_SENDER_GET_BY_ID: '/api/nbx/bws/address/getSenderById',
    /** 地址：发件人/订购人-新增 */
    ADDRESS_SENDER_ADD: '/api/nbx/bws/address/addSender',
    /** 地址：发件人/订购人-修改 */
    ADDRESS_SENDER_UPDATE: '/api/nbx/bws/address/updateSender',
    /** 地址：发件人/订购人-删除 */
    ADDRESS_SENDER_DEL: '/api/nbx/bws/address/delSender',

    /** 订单相关 */
    /** 结算 */
    ORDER_CHECKOUT: '/api/nbx/bws/order/checkout',
    /** 订单创建 */
    ORDER_CREATE: '/api/nbx/bws/order/create',
    /** 订单列表 */
    ORDER_LIST: '/api/nbx/bws/order/list',
    /** 订单详情 */
    ORDER_DETAIL: '/api/nbx/bws/order/detail',
    /** 订单取消 */
    ORDER_CANCEL: '/api/nbx/bws/order/cancel',

    /** 支付 */
    /** 申请 */
    PAYMENT_REQUEST:'/api/nbx/bws/payment/request',
    /** 校验 */
    PAYMENT_VALIDATE:'/api/nbx/bws/payment/validate',

    /** 校验 */
    PAYMENT_GET_JSSDK_CONFIG:'/api/nbx/bws/payment/getJsSdkConfig',
};

