import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    props: true,
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import(/* webpackChunkName: "loading" */ '../views/LoadingView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '../views/RegisterView')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
  },
  {
    path: '/goodsList/:keywords?/:flag?',
    name: 'goodsList',
    component: () => import(/* webpackChunkName: "goodsList" */ '../views/GoodsListView.vue'),
    props: true,
  },
  {
    path: '/goodsDetail/:id',
    name: 'goodsDetail',
    component: () => import(/* webpackChunkName: "goodsDetail" */ '../views/GoodsDetailView.vue'),
    props: true,
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: () => import(/* webpackChunkName: "shoppingCart" */ '../views/ShoppingCartView.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "mine" */ '../views/MineView.vue'),
  },
  {
    path: '/subscriberList',
    name: 'subscriberList',
    component: () => import(/* webpackChunkName: "subscriberList" */ '../views/SubscriberListView.vue'),
  },
  {
    path: '/subscriberEdit/:id?',
    name: 'subscriberEdit',
    component: () => import(/* webpackChunkName: "subscriberEdit" */ '../views/SubscriberEditView.vue'),
    props: true,
  },
  {
    path: '/recipientList',
    name: 'recipientList',
    component: () => import(/* webpackChunkName: "recipientList" */ '../views/RecipientListVue.vue'),
  },
  {
    path: '/recipientEdit/:id?',
    name: 'recipientEdit',
    component: () => import(/* webpackChunkName: "recipientEdit" */ '../views/RecipientEditView.vue'),
    props: true,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/CheckoutView.vue'),
  },
  {
    path: '/orderList/:type?',
    name: 'orderList',
    props: true,
    component: () => import(/* webpackChunkName: "orderList" */ '../views/OrderListView.vue'),
  },
  {
    path: '/orderDetail/:orderId',
    name: 'orderDetail',
    props: true,
    component: () => import(/* webpackChunkName: "orderDetail" */ '../views/OrderDetailView.vue'),
  },
  {
    path: '/payment/:orderId/:total',
    name: 'payment',
    props: true,
    component: () => import(/* webpackChunkName: "payment" */ '../views/PaymentView.vue'),
  },
  {
    path: '/paymentSuc',
    name: 'paymentSuc',
    props: true,
    component: () => import(/* webpackChunkName: "paymentSuc" */ '../views/PaymentSuccessView.vue'),
  },
  {
    path: '/wexinPaying',
    name: 'wexinPaying',
    props: true,
    component: () => import(/* webpackChunkName: "pawexinPayingyment" */ '../views/WeixinPayingView.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
